import { i18n } from "i18next";

import { Languages } from "../types/Languages";
import { Translations } from "../types/ProductPreset";

export const translate = (
    translations: Translations,
    languageSettings: i18n
) => {
    const currentLanguage = languageSettings.language;
    const fallbackLanguage = languageSettings.options.fallbackLng as string;

    const translated = translations[currentLanguage as Languages];

    return translated
        ? translated
        : translations[fallbackLanguage as Languages] ?? "";
};
