import { Tracker } from "../track";

export class PostCheckoutClient {
    constructor(private client: typeof fetch, private tracker: Tracker) {}

    public async sendFeedback(rate: number) {
        return this.tracker.track("feedback_sent", { rate });
    }

    public async reset(abortPayment: boolean, language: string): Promise<void> {
        const debug =
            // Set the value to either 'success' or 'failure'
            import.meta.env.VITE_SIMULATE_HW
                ? `?simulateResponse=${import.meta.env.VITE_SIMULATE_HW}`
                : "";
        try {
            await this.client(`/ezio/v1/reset${debug}`, {
                method: "POST",
                headers: {
                    "Accept-Language": language
                },
                body: JSON.stringify({ abortPayment })
            });
        } catch (err) {
            console.warn("reset failed with", err);
        }
    }
}
