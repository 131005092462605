import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dialog } from "./Dialog";
import { RootState, useAnyState } from "./state/StateProvider";
import * as svg from "./svg";

const COUNTDOWN_SECONDS = 5;

const timeoutForState = (state: RootState): number | null => {
    switch (state.kind) {
        case "scan":
            return state.subState.kind === "checkingOut" ? 100 : 40;
        case "checkoutSuccess":
            return 15;
        case "operator":
            return 15;
        case "flappy":
            return 180;
        case "error":
            return 10;
        default:
            return null;
    }
};

const isModalShownForState = (state: RootState): boolean => {
    switch (state.kind) {
        case "scan":
            return true;
        default:
            return false;
    }
};

export const Timeout = ({ children }: PropsWithChildren) => {
    const interval = useRef<number>(0);

    const [idleSeconds, setIdleSeconds] = useState(0);

    const { state, dispatch } = useAnyState();

    const timeout = timeoutForState(state);
    const isTimeoutEnabled = timeout != null && !import.meta.env.DEV;
    const hasTimedOut = isTimeoutEnabled && idleSeconds >= timeout;
    const remainingSeconds = isTimeoutEnabled && timeout - idleSeconds;
    const isModalShown =
        isModalShownForState(state) &&
        remainingSeconds &&
        remainingSeconds <= COUNTDOWN_SECONDS;

    const cleanUp = () => {
        clearInterval(interval.current);
        setIdleSeconds(0);
    };

    const restartTimer = () => {
        cleanUp();
        interval.current = window.setInterval(() => {
            setIdleSeconds((s) => s + 1);
        }, 1000);
    };

    const reset = () => {
        dispatch({ kind: "reset" });
    };

    useEffect(() => {
        if (hasTimedOut) reset();
    }, [hasTimedOut]);

    useEffect(() => {
        if (isTimeoutEnabled) restartTimer();
        return cleanUp;
    }, [state]);

    return (
        <>
            {isModalShown && (
                <TimeoutModal
                    seconds={remainingSeconds}
                    onContinue={restartTimer}
                    onStartOver={reset}
                />
            )}
            {children}
        </>
    );
};

const TimeoutModal = ({
    seconds,
    onContinue,
    onStartOver
}: {
    seconds: number;
    onContinue: () => void;
    onStartOver: () => void;
}) => {
    const { t } = useTranslation();

    return (
        <Dialog
            image={svg.itemLost}
            title={t("stillThere")}
            onBackgroundClick={onContinue}
            primaryButton={{ title: t("continue"), action: onContinue }}
            secondaryButton={{ title: t("startOver"), action: onStartOver }}
        >
            <div className="text-3xl text-primary-800">
                {formatDuration(seconds)}
            </div>
        </Dialog>
    );
};

const formatDuration = (duration: number) => {
    const seconds = duration % 60;
    const minutes = (duration - seconds) / 60;

    return `${padZero(minutes)}:${padZero(seconds)}`;
};

const padZero = (number: number) => number.toString().padStart(2, "0");
