import { motion } from "framer-motion";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import coin from "../../../assets/coin.svg";
import { PromotionalProduct } from "../../types/Promotion";
import * as Price from "../../utils/Price";
import { useRootState } from "../state/StateProvider";

export const PromoPrice = ({
    discountedPrice,
    originalPrice
}: {
    discountedPrice: string;
    originalPrice: string;
}) => {
    return (
        <div className="flex gap-2 text-xs">
            <div className="thin text-slate-500 line-through">
                {originalPrice}
            </div>
            <div className="thin">{discountedPrice}</div>
        </div>
    );
};

export const PromoBanner = ({
    promotionalProduct
}: {
    promotionalProduct: PromotionalProduct;
}) => {
    const { dispatch } = useRootState("scan");
    const { t } = useTranslation();

    const [tapped, setTapped] = useState(false);

    const handleTap = () => {
        setTapped(true);
    };

    const coinAnimation = (
        <>
            {tapped && (
                <>
                    {[
                        ["-40%", "-150%"],
                        ["-20%", "-180%"],
                        ["0%", "-200%"],
                        ["20%", "-180%"],
                        ["40%", "-150%"]
                    ].map(([x, y]) => (
                        <motion.img
                            key={`${x}${y}`}
                            className="absolute z-20 pl-32 pt-5"
                            src={coin}
                            initial={{ opacity: 1 }}
                            transition={{ duration: 1.5, ease: "easeOut" }}
                            animate={{
                                opacity: [1, 1, 1, 0],
                                transform: `translateX(${x}) translateY(${y}) `
                            }}
                            onAnimationComplete={() => setTapped(false)}
                        ></motion.img>
                    ))}
                </>
            )}
        </>
    );

    return (
        <div
            className="h-full"
            onClick={() => {
                handleTap();
                dispatch({
                    kind: "addProduct",
                    payload: {
                        kind: "manuallyAdded",
                        code: promotionalProduct.code,
                        quantity: 1,
                        analyticsSource: "promo"
                    }
                });
            }}
        >
            {coinAnimation}
            <div className="relative py-2">
                <div
                    key={promotionalProduct.name}
                    className="
                            press-effect
                            flex
                            shrink-0
                            items-center
                            gap-7
                            break-words
                            rounded
                            border-2
                            border-primary-200
                            bg-primary-100
                            p-3 text-sm
                        "
                >
                    <img
                        src={promotionalProduct.imageUrl}
                        className="max-h-7 bg-transparent"
                    />

                    <div className="grow">
                        <div className="font-bold capitalize">
                            {promotionalProduct.name}
                        </div>
                        <PromoPrice
                            discountedPrice={Price.format(
                                promotionalProduct.discountedPrice
                            )}
                            originalPrice={Price.format(
                                promotionalProduct.originalPrice
                            )}
                        />
                    </div>
                </div>
                <div className="absolute left-2 top-0 rounded bg-primary-500 px-3 py-1 text-[18px] font-bold text-white">
                    {t("promo")}
                </div>
            </div>
        </div>
    );
};
