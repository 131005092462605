import { trace } from "firebase/performance";

import { getPerformanceInstance } from "../firebase";
import { ApiPromotionalProduct } from "../types/Promotion";

export class ProductClient {
    constructor(private client: typeof fetch) {}

    public async getPromotionalProduct(
        code: string
    ): Promise<ApiPromotionalProduct> {
        const t = trace(getPerformanceInstance(), "getPromoItems");

        t.start();
        const response = await this.client(
            `/ezio/v1/promotional-price?code=${code}`,
            {
                method: "GET"
            }
        );
        t.stop();

        return await response.json();
    }
}
