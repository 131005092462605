import { requireAction, requireState } from "./Common";
import { OrderClient } from "../../clients/OrderClient";
import { getDate } from "../../date";
import { ActionHandler } from "../../types/ActionHandler";
import { isValidOperatorBadge } from "../hooks/useBarcodeScanner";

export const scanOperatorBadge: ActionHandler = async (state, action) => {
    requireState(state, "welcome");
    const { scannedBadge } = requireAction(state, action, "scanOperatorBadge");

    if (isValidOperatorBadge(scannedBadge)) {
        return {
            ...state,
            kind: "operator",
            subState: { kind: "idle" }
        };
    }

    return {
        ...state,
        kind: "welcome",
        subState: { kind: "idle" }
    };
};

export const showOrders: ActionHandler = async (state, action, client) => {
    requireState(state, "operator");
    requireAction(state, action, "showOrders");

    const orderClient = new OrderClient(client);
    const today = getDate(new Date());
    const { orders, totalCount } = await orderClient.list(today, 0);
    return {
        ...state,
        kind: "operator",
        subState: {
            kind: "orders",
            date: today,
            orders,
            totalCount,
            offset: 0
        }
    };
};

export const loadMoreOrders: ActionHandler = async (state, action, client) => {
    const operatorState = requireState(state, "operator");
    const { offset, date } = requireAction(state, action, "loadMoreOrders");

    const orderClient = new OrderClient(client);
    const { orders, totalCount } = await orderClient.list(date, offset);
    return {
        ...operatorState,
        subState: {
            kind: "orders",
            date,
            offset,
            orders,
            totalCount
        }
    };
};

export const showInsights: ActionHandler = async (state, action, client) => {
    const operatorState = requireState(state, "operator");
    const { date, groupBy } = requireAction(state, action, "showInsights");

    const orderClient = new OrderClient(client);
    const insights = await orderClient.getRevenue(date, groupBy);

    return {
        ...operatorState,
        subState: {
            kind: "insights",
            date,
            groupBy,
            insights
        }
    };
};
