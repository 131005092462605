import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../Button";
import { Dialog } from "../Dialog";
import { useAnyState } from "../state/StateProvider";

export const ChangeLanguageDialog = () => {
    const { t, i18n } = useTranslation();
    const [isLanguagePickerOpen, setIsLanguagePickerOpen] = useState(false);
    const { dispatch } = useAnyState();

    const languages: string[] = ["ch", "de", "fr", "it", "en"];
    const current = i18n.language.toUpperCase();

    return (
        <>
            {isLanguagePickerOpen && (
                <Dialog
                    title={t("changeLanguage")}
                    onBackgroundClick={() => setIsLanguagePickerOpen(false)}
                >
                    <div className="flex justify-center gap-4">
                        {languages.map((code) => (
                            <Button
                                onClick={() => {
                                    dispatch({
                                        kind: "changeLanguage",
                                        code
                                    });
                                }}
                                className={
                                    code !== i18n.language
                                        ? "!border-primary-200 text-primary-900 text-opacity-60"
                                        : ""
                                }
                                key={code}
                                label={code.toUpperCase()}
                            />
                        ))}
                    </div>
                </Dialog>
            )}
            <Button
                className="z-10"
                onClick={() => setIsLanguagePickerOpen(true)}
                label={current}
            />
        </>
    );
};
