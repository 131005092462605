import { useEffect, useState } from "react";

import { useConfig } from "./components/ConfigProvider";
import { Tenant } from "./types/Config";

export type Color = "Green" | "Orange" | "Brand" | "Red" | "Yellow";

const toRGB = (tenant: Tenant, color: Color) => {
    const brandColor = function () {
        switch (tenant) {
            case "avec":
                return [128, 1, 51];
            case "kkiosk":
                return [1, 197, 245];
        }
    };

    switch (color) {
        case "Green":
            return [1, 255, 1];
        case "Brand":
            return brandColor();
        case "Orange":
            return [255, 138, 1];
        case "Red":
            return [255, 1, 1];
        case "Yellow":
            return [255, 255, 1];
    }
};

type LampConfiguration = {
    vendorId: number;
    productId: number;
    options: SerialOptions;
    constructPayload: (tenant: Tenant, color: Color) => Uint8Array;
};

const LAMP_CONFIGURATION: Record<string, LampConfiguration> = {
    // Eventually, this will be the only SCO lamp and we can simplify this file
    PYRAMID: {
        vendorId: 0x03eb,
        productId: 0x2404,
        options: {
            baudRate: 115200,
            dataBits: 8,
            stopBits: 1,
            parity: "none"
        },
        constructPayload: (theme, rgb) =>
            new Uint8Array([0x5a, 0xff, 0xe8, ...toRGB(theme, rgb), 0xa5])
    },
    DEPRECATED_PRIMELCO: {
        vendorId: 0x0403,
        productId: 0x6001,
        options: {
            baudRate: 9600
        },
        constructPayload: (theme, color) =>
            new Uint8Array([83, ...toRGB(theme, color), 1, 1, 1, 13, 10])
    }
};

const isSupported = "serial" in navigator;

const getLampConfiguration = ({
    usbVendorId,
    usbProductId
}: Partial<SerialPortInfo>) => {
    if (!usbVendorId || !usbProductId) return undefined;

    return Object.values(LAMP_CONFIGURATION).find(
        ({ vendorId, productId }) =>
            vendorId === usbVendorId && productId === usbProductId
    );
};

const requestPort = async (): Promise<SerialPort> => {
    const port = await navigator.serial.requestPort({
        filters: Object.values(LAMP_CONFIGURATION).map(
            ({ vendorId, productId }) => ({
                usbVendorId: vendorId,
                usbProductId: productId
            })
        )
    });

    return port;
};

const getPort = async (): Promise<SerialPort | undefined> => {
    if (!isSupported) return;

    const ports = await navigator.serial.getPorts();

    const port = ports.find((port) => {
        const info = port.getInfo();
        return getLampConfiguration(info) !== undefined;
    });

    return port;
};

const connect = async (port: SerialPort) => {
    const portInfo = port.getInfo();
    const lampConfiguration = getLampConfiguration(portInfo);

    return {
        setColor: (tenant: Tenant) => async (color: Color) => {
            if (!lampConfiguration) return;

            // Throws exception when already open
            await port.open(lampConfiguration.options).catch(console.warn);
            const writer = port.writable?.getWriter();

            if (!writer) return;

            const bytes = lampConfiguration.constructPayload(tenant, color);
            console.log("Writing bytes", bytes);

            await writer.write(bytes);
            consoleLight(tenant)(color);
            writer.releaseLock();
            await port.close().catch(console.warn);
        }
    };
};

const consoleLight = (tenant: Tenant) => async (color: Color) => {
    console.log(`%c ${color}`, `color: rgb(${toRGB(tenant, color)})`);
};

export const useLamp = () => {
    const { tenant } = useConfig();

    const connectAndUpdateState = async (port: SerialPort) => {
        const { setColor } = await connect(port);
        setState((state) => ({
            ...state,
            connected: true,
            setColor: setColor(tenant)
        }));
    };

    const [state, setState] = useState({
        connected: false,
        enabled: isSupported,
        connect: () =>
            requestPort()
                .then((port) => connectAndUpdateState(port))
                .catch(() =>
                    setState((state) => ({ ...state, enabled: false }))
                ),
        setColor: consoleLight(tenant)
    });

    useEffect(() => {
        getPort().then((port) => {
            if (port) {
                connectAndUpdateState(port);
            }
        });
    }, [tenant]);

    return state;
};
